<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <GroupHeader :group="org" />
      <v-alert v-if="status && status.connected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: org.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
        </p>
        <p>
          <v-btn :to="{ name: 'organizationWelcome', params: {id: org.id}}" class="mr-2">Manage</v-btn>
        </p>
      </v-alert>

      <vue-markdown v-if="org.description" class="markdown px-4 pt-8" :html="false">{{org.description }}</vue-markdown>

      <v-card-title class="subtitle">My Sub Challenges</v-card-title>
      <div v-if="status && status.connected">
        <v-card-text>
          Create your own challenge and invite your co-workers to join for a healthy bit of competition.
        </v-card-text>
        <v-card-text>
          <v-btn :color="org.color||'primary'" :dark="$helpers.isDarkColor(org.color || 'primary')" :to="{ name: 'organizationChallengesCreate', params: {id: org.id}}" class="mr-2">Start a Challenge</v-btn>
          <v-btn outlined :color="org.color||'primary'" :to="{ name: 'organizationChallenges', params: {id: org.id}}">My Sub Challenge</v-btn>
        </v-card-text>
      </div>
      <v-card-text v-else>
        Join this group first in order to create and join sub challenges.
      </v-card-text>

      <div v-if="org.events && org.events.length >= 0">
        <v-card-title class="subtitle">Events and challenges by {{org.name}}</v-card-title>
        <EventGrid :items="org.events" showLogos class="mx-4 mb-4"></EventGrid>
      </div>      
      <br/>
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import EventGrid from "@/components/EventGrid.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ProfileGroupsView",
  components: {
    EventGrid,
    GroupHeader,
    VueMarkdown,
  },
  props: {
  },
  data() {
    return {
      org: null,
      status: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.org = response.data;

      if (this.org == null) {
        this.$router.push('/');
        return;
      }

      var meta = {
        title: this.org.name + ' on ' + tenant.name,
        description: (this.org.description || this.org.name) + ' on ' + tenant.title,
        image: this.org.logo || this.org.img,
      };
      EventBus.$emit('page-header-change', meta);

    },

    async getGroupStatus() {
      if (this.user && this.org) {
        var response = await profileService.getGroupStatus(this.org.id);
        this.status = response.data;
      }
    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
        await this.getGroupStatus();
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return this.org == null ? [] : [
        { text: this.org.name, disabled: true },
      ]
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

